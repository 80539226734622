import React from 'react'
import { Link, graphql } from 'gatsby'
import BucketIconOne from '../assets/bucket-icon-1.svg'
import BucketIconTwo from '../assets/bucket-icon-2.svg'
import BucketIconThree from '../assets/bucket-icon-3.svg'
import BucketIconFour from '../assets/bucket-icon-4.svg'
import LinkArrowLight from '../assets/link-arrow-light.svg'
import LinkArrow from '../assets/link-arrow.svg'
import '../styles/four-bucket.css'

export default function FourBucket({ layoutPiece }) {
  const SuiteBuckets = layoutPiece?.suiteCards
  return (
    <div className="four-bucket standard-component-spacing">
      <div className="content-cap">
        <div className="four-bucket-header">
          <h2>{layoutPiece?.headlineText}</h2>
        </div>
        <div className="bucket-start">
          <div className="four-bucket-grid">
            {SuiteBuckets?.map((suiteBucket, index) => {
              const link = suiteBucket?.exploreMoreLink
              function GetBucketIcon() {
                switch(index){
                  case 0:
                    return <BucketIconOne className="bucket-icon-image"/>
                  case 1:
                    return <BucketIconTwo className="bucket-icon-image"/>
                  case 2:
                    return <BucketIconThree className="bucket-icon-image"/>
                  case 3:
                    return <BucketIconFour className="bucket-icon-image"/>
                  default:
                    return <BucketIconOne className="bucket-icon-image"/>
                }
              }
              function GetBucketBackground() {
                switch(suiteBucket?.backgroundColor){
                  case 'Black':
                    return "four-bucket-item dark-background"
                  case 'Grey':
                    return "four-bucket-item grey-background"
                  case 'Off-white':
                    return "four-bucket-item eggshell-background"
                  default:
                    return "four-bucket-item"
                }
              }
              const CheckSlug = suiteBucket.exploreMoreLink.slug
              function GetBucketSlug() {
                if(CheckSlug) {
                  return CheckSlug
                }
                return "/"
              }
              return(
              <Link to={GetBucketSlug()} title="" key={index}>
                <div className={GetBucketBackground()}>
                  <GetBucketIcon />
                  <h3>{index === 2 ? <br/> : ""}{index === 3 ? <br/> : ""}{suiteBucket?.headlineText}</h3>
                  <h4>{suiteBucket?.description?.description}</h4>
                  {
                    link && link?.fullUrl && suiteBucket.backgroundColor === 'Black' ?
                      <div className="cta-link-default light-cta-link" title={link?.title}>{link?.text}<span className="link-arrow-icon"><LinkArrowLight /></span></div>
                    :
                    link && link?.fullUrl ?
                      <div className="cta-link-default dark-cta-link" title={link?.title}>{link?.text}<span className="link-arrow-icon"><LinkArrow /></span></div>
                    : ""
                  }
                  {
                    link && link.slug && suiteBucket.backgroundColor === 'Black' ?
                      <div className="cta-link-default light-cta-link" title={link?.title}>{link?.text}<span className="link-arrow-icon"><LinkArrowLight /></span></div>
                    :
                    link && link?.slug ?
                      <div className="cta-link-default dark-cta-link" title={link?.title}>{link?.text}<span className="link-arrow-icon"><LinkArrow /></span></div>
                    : ""
                  }
                </div>
               </Link>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment SuiteCards on ContentfulSuiteCardsComponent {
    id
    headlineText
    backgroundColor
    suiteCards {
      backgroundColor
      headlineText
      description {
        description
      }
      exploreMoreLink {
        text
        fullUrl
        slug
        title
      }
    }
  }
`
